import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const Warpper = styled.div`
  max-width: 1200px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  @media (min-width: 620px) {
    padding: 0;
  }
`;

export const Container = styled.div`
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;

  h1 {
    color: #fff;
    margin-bottom: 12px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const EmptyBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  svg {
    margin: 24px auto;
  }
`;

export const AddProduct = styled(Link)`
  min-width: 110px;
  display: flex;
  align-items: center;
  margin: 5px 0 0;
  padding: 0 12px;
  height: 44px;
  background: #3b9eff;
  color: #fff;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  &:hover {
    background: ${darken(0.05, '#3b9eff')};
  }
`;
