import styled from 'styled-components';
import { darken } from 'polished';

export const Warpper = styled.div`
  max-width: 1200px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  @media (min-width: 620px) {
    padding: 0;
  }
`;

export const Container = styled.div`
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;

  > h1 {
    color: #fff;
    margin: 12px 0;
    font-size: 24px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  form {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    label {
      color: #fff;
      margin: 8px 0;
    }

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      a {
        min-width: 110px;
        margin: 5px 0 0;
        padding: 0 12px;
        height: 44px;
        color: #fff;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button {
        min-width: 110px;
        margin: 5px 0 0;
        padding: 0 12px;
        height: 44px;
        background: #3b9eff;
        color: #fff;
        font-weight: bold;
        border: 0;
        border-radius: 4px;
        font-size: 16px;
        transition: background 0.2s;
        &:hover {
          background: ${darken(0.05, '#3b9eff')};
        }
      }
    }
  }
`;

export const DeleteBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  h1 {
    color: #fff;
    margin: 12px 0;
    font-size: 24px;
  }

  p {
    color: #fff;
  }

  button {
    width: 100%;
    margin: 15px 0 0;
    height: 44px;
    background: #f64c75;
    color: #fff;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.1, '#f64c75')};
    }
  }
`;
