import styled from 'styled-components';
import { darken } from 'polished';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${(props) => (props.isHeader ? 'transparent' : '#fff')};
  color: ${(props) => (props.isHeader ? '#fff' : '#7159c1')};
  margin: 4px 0;
  border-radius: 4px;
  padding: 12px;

  strong,
  div {
    display: flex;

    &:last-child {
      width: 120px;
    }
  }

  a {
    color: #3b9eff;
    transition: color 0.2s;
    &:hover {
      color: ${darken(0.05, '#3b9eff')};
    }
  }

  button {
    border: 0;
    background: none;
    color: #f64c75;
    transition: color 0.2s;
    margin-left: 12px;
    &:hover {
      color: ${darken(0.1, '#f64c75')};
    }
  }
`;
