import React, { useState, useEffect } from 'react';
import { MdAdd, MdInbox } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert'; // Import

import api from '~/services/api';
import ListOfCompanyProducts from '~/components/ListOfCompanyProducts';

import { Warpper, Container, EmptyBox, AddProduct } from './styles';

export default function ListOfProducts({ companyId }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function load() {
      const response = await api.get(`companies/${companyId}/products`);
      setProducts(response.data);
    }

    load();
  }, [companyId]);

  function handleDelete(item) {
    confirmAlert({
      title: 'Eliminar instalación',
      message: `¿Seguro que desea eliminar el producto ${item.product.name}?`,
      buttons: [
        {
          label: 'Continuar',
          onClick: async () => {
            await api.delete(`companies/${companyId}/products/${item.id}`);
            setProducts((oldItems) =>
              oldItems.filter((el) => el.id !== item.id)
            );
          },
        },
        {
          label: 'Cancelar',
        },
      ],
    });
  }

  return (
    <Warpper>
      <Container>
        <h1>
          Productos
          <AddProduct to={`/company/${companyId}/product/-1`}>
            <MdAdd size={24} color="#fff" /> AÑADIR
          </AddProduct>
        </h1>

        {products.length === 0 ? (
          <EmptyBox>
            <MdInbox size={64} color="#fff" />
            La empresa no tiene productos
          </EmptyBox>
        ) : (
          <ListOfCompanyProducts
            companyId={companyId}
            data={products}
            onDelete={handleDelete}
          />
        )}
      </Container>
    </Warpper>
  );
}
