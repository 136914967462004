import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { startOfMonth, subMonths, format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

import api from '~/services/api';
import { DatePicker } from '~/components/DatePicker';

import {
  Container,
  ChartWrapper,
  ChartContainer,
  ChartTitle,
  Header,
  ActionsContainer,
  Button,
  GridContainer,
} from './styles';

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const colors = [
  'rgba(255, 159, 64, 0.3)',
  'rgba(54, 162, 235, 0.3)',
  'rgba(255, 206, 86, 0.3)',
  'rgba(75, 192, 192, 0.3)',
  'rgba(153, 102, 255, 0.3)',
  'rgba(255, 99, 132, 0.3)',
];

const borderColors = [
  'rgba(255, 159, 64, 0.8)',
  'rgba(54, 162, 235, 0.8)',
  'rgba(255, 206, 86, 0.8)',
  'rgba(75, 192, 192, 0.8)',
  'rgba(153, 102, 255, 0.8)',
  'rgba(255, 99, 132, 0.8)',
];

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export default function CompanyActivity({ match }) {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [usersLabels, setUserLabels] = useState([]);
  const [userDatasets, setUserDatasets] = useState([]);
  const [companyName, setCompanyName] = useState();

  const [dateInterval, setDateInterval] = useState({
    from: startOfMonth(subMonths(new Date(), 2)),
    to: new Date(),
  });
  const gridRef = useRef();

  const companyId = parseInt(match.params.id, 10);

  const [rowData, setRowData] = useState([]);

  const [columnDefs] = useState([
    { field: 'product_name', headerName: 'Producto', width: 130 },
    { field: 'installation_version', headerName: 'Versión', width: 120 },
    { field: 'installation_pc_name', headerName: 'Dispositivo', width: 200 },
    { field: 'username', headerName: 'Usuario', width: 190 },
    { field: 'installation_os', headerName: 'Sistema Operativo', width: 280 },
    { field: 'ip_address', headerName: 'Ip', width: 130 },
    { field: 'installation_code', headerName: 'Serial', width: 300 },
    {
      field: 'created_at',
      headerName: 'Fecha',
      width: 190,
      type: ['dateColumn', 'nonEditableColumn'],
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      width: 100,
      editable: false,
      floatingFilter: true,
      resizable: true,
      sortable: true,
      filter: 'agTextColumnFilter',
    }),
    []
  );

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
      medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateParts = cellValue.split('/');
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    async function load() {
      const from = format(dateInterval.from, 'yyyy-MM-dd');
      const to = format(dateInterval.to, 'yyyy-MM-dd');

      const [respDevices, respUses, respLogs, respCompany] = await Promise.all([
        api.get(`access-by-devices/${companyId}/${from}/${to}`),
        api.get(`access-by-users/${companyId}/${from}/${to}`),
        api.get(`access-logs/${companyId}/${from}/${to}`),
        api.get(`companies/${companyId}`),
      ]);

      setCompanyName(respCompany.data.name);

      const dataMonths = new Map();
      const dataNames = new Map();

      const dataRows = respLogs.data.map((item) => {
        return {
          product_name: item.product_name,
          installation_version: item.installation_version,
          installation_pc_name: item.installation_pc_name,
          username: item.username,
          installation_os: item.installation_os,
          ip_address: item.ip_address,
          installation_code: item.installation_code,
          created_at: format(new Date(item.created_at), 'dd/MM/yyyy'),
        };
      });

      respDevices.data.forEach((item) => {
        const key = `${months[item.month - 1]} ${item.year}`;
        if (dataMonths.has(key)) {
          dataMonths.get(key).push(item);
        } else {
          dataMonths.set(key, [item]);
        }

        if (!dataNames.has(item.installation_pc_name)) {
          dataNames.set(item.installation_pc_name);
        }
      });
      const dataKeys = [...dataMonths.keys()];
      const data = dataKeys.map((key) => {
        const value = dataMonths.get(key);

        const items = value.map((v) => ({
          [`${v.installation_pc_name}`]: Number(v.access_count),
        }));

        return Object.assign(
          {
            x: key,
          },
          ...items
        );
      });
      const datasetData = [...dataNames.keys()].map((item, index) => {
        return {
          label: item,
          data,
          backgroundColor: colors[index],
          borderColor: borderColors[index],
          borderWidth: 1,
          parsing: {
            yAxisKey: item,
          },
        };
      });

      const dataUserMonths = new Map();
      const dataNameUsers = new Map();
      respUses.data.forEach((item) => {
        const key = `${months[item.month - 1]} ${item.year}`;
        if (dataUserMonths.has(key)) {
          dataUserMonths.get(key).push(item);
        } else {
          dataUserMonths.set(key, [item]);
        }

        if (!dataNameUsers.has(item.username || 'No definido')) {
          dataNameUsers.set(item.username || 'No definido');
        }
      });
      const dataUserKeys = [...dataUserMonths.keys()];
      const dataUsers = dataUserKeys.map((key) => {
        const value = dataUserMonths.get(key);

        const items = value.map((v) => ({
          [`${v.username || 'No definido'}`]: Number(v.access_count),
        }));

        return Object.assign(
          {
            x: key,
          },
          ...items
        );
      });
      const datasetDataUsers = [...dataNameUsers.keys()].map((item, index) => {
        return {
          label: item || '',
          data: dataUsers,
          backgroundColor: colors[index],
          borderColor: borderColors[index],
          borderWidth: 1,
          parsing: {
            yAxisKey: item,
          },
        };
      });

      setLabels(dataKeys);
      setDatasets(datasetData);

      setUserLabels(dataUserKeys);
      setUserDatasets(datasetDataUsers);

      setRowData(dataRows);
    }

    load();
  }, [companyId, dateInterval]);

  const onChangeDate = useCallback((value) => {
    setDateInterval(value);
  }, []);

  const handleExportCsv = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const options = {
    responsive: true,
    color: '#f4f4f4',
    scales: {
      x: {
        ticks: {
          color: '#efefef',
        },
        grid: {
          color: (ctx) => {
            if (ctx.tick && ctx.tick.value > 0) {
              return 'transparent';
            }

            return '#efefef';
          },
        },
      },
      y: {
        ticks: {
          color: '#efefef',
        },
        grid: {
          color: (ctx) => {
            if (ctx.tick.value > 0) {
              return 'transparent';
            }

            return '#efefef';
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
      datalabels: {
        color: 'white',
        font: {
          size: '14px',
        },
        formatter(value, context) {
          return value[context.dataset.label] || 0;
        },
      },
    },
  };
  const data = {
    labels,
    datasets,
  };

  const userData = {
    labels: usersLabels,
    datasets: userDatasets,
  };

  return (
    <Container>
      <Header>
        <h1>{companyName}</h1>
        <ActionsContainer>
          <Button type="button" onClick={handleExportCsv}>
            Descargar CSV
          </Button>
          <DatePicker
            from={dateInterval.from}
            to={dateInterval.to}
            onChange={onChangeDate}
          />
        </ActionsContainer>
      </Header>
      <ChartWrapper>
        <ChartContainer>
          <ChartTitle>Accesos por dispositivos</ChartTitle>
          <Bar options={options} data={data} plugins={[ChartDataLabels]} />
        </ChartContainer>
        <ChartContainer>
          <ChartTitle>Accesos por usuarios</ChartTitle>
          <Bar options={options} data={userData} plugins={[ChartDataLabels]} />
        </ChartContainer>
      </ChartWrapper>
      <GridContainer className="ag-theme-balham-dark">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          columnTypes={columnTypes}
          defaultColDef={defaultColDef}
          style={{ width: '100%', height: '100%;' }}
        />
      </GridContainer>
    </Container>
  );
}
