import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { IoIosArrowBack } from 'react-icons/io';
import * as Yup from 'yup';

import Input from '~/components/Input';
import Select from '~/components/Select';
import ListOfInstalations from '~/components/ListOfInstalations';
import history from '~/services/history';
import api from '~/services/api';

import { Warpper, Container, ActionsBox } from './styles';

export default function CompanyProduct({ match }) {
  const id = parseInt(match.params.id, 10);
  const companyId = parseInt(match.params.companyId, 10);
  const [products, setProducts] = useState([]);
  const [selectedProd, setSelectedProd] = useState(null);
  const [companyProduct, setCompanyProduct] = useState(null);

  const formRef = useRef(null);

  useEffect(() => {
    async function load() {
      const response = await api.get('products');
      const options = response.data.map((prod) => ({
        value: prod.id,
        label: prod.name,
      }));

      await setProducts(options);

      if (id > 0) {
        const resp = await api.get(`companies/${companyId}/products/${id}`);
        const newSelectedProd = {
          value: resp.data.product_id,
          label: resp.data.product.name,
        };

        const data = {
          ...resp.data,
          product: newSelectedProd,
        };
        setSelectedProd(newSelectedProd);
        setCompanyProduct(data);
      } else {
        setCompanyProduct({});
        setSelectedProd(options[0]);
      }
    }

    load();
  }, [companyId, id]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        product: Yup.number().required(),
        concurrent_users: Yup.number().required(),
        allowed_facilities: Yup.number().required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      if (id === -1) {
        const response = await api.post(`companies/${companyId}/products`, {
          ...data,
          product_id: data.product,
        });
        toast.success('Producto se ha asignado con éxito!');
        history.push(`/company/${companyId}/product/${response.data.id}`);
      } else {
        await api.put(`companies/${companyId}/products/${id}`, {
          ...data,
          product_id: data.product,
        });
        toast.success('Datos de producto actualizado con éxito!');
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error(err.response.data.error);
      }
    }
  }

  return (
    <>
      <Warpper>
        <Container>
          <h1>
            <Link to={`/company/${companyId}`}>
              <IoIosArrowBack color="#fff" size={32} />
            </Link>
            {id === -1 ? 'Añadir producto' : 'Actualizar producto'}
          </h1>

          {companyProduct && (
            <Form
              ref={formRef}
              initialData={companyProduct}
              onSubmit={handleSubmit}
            >
              <Select
                name="product"
                label="Producto"
                placeholder="Seleccionar producto"
                options={products}
                defaultValue={selectedProd}
                autoFocus
              />
              <Input
                name="concurrent_users"
                label="Nº de usuarios concurrentes"
                placeholder="Asignar nº de usuarios concurrentes"
              />
              <Input
                name="allowed_facilities"
                label="Nº de instalaciones activas permitidas"
                placeholder="Asignar nº de instalaciones activas permitidas"
              />

              <ActionsBox>
                <button type="submit">GUARDAR</button>
              </ActionsBox>
            </Form>
          )}
        </Container>

        {id > 0 && <ListOfInstalations id={id} companyId={companyId} />}
      </Warpper>
    </>
  );
}
