import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  @media (min-width: 620px) {
    padding: 0;
  }
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ChartContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 16px;
  border-radius: 8px;
  flex: 1;
`;

export const ChartTitle = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 12px;

  h1 {
    font-size: 1.6rem;
    color: #f4f4f4;
  }
`;

export const ActionsContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 16px;
`;

export const GridContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  height: calc(100vh - 565px);
`;

export const Button = styled.button`
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  border: 0;
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.3);
  color: #f4f4f4;
  cursor: pointer;
`;
