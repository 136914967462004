import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Button = styled.button`
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  border: 0;
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.3);
  color: #f4f4f4;
  cursor: pointer;
`;

export const ButtonConfirm = styled(Button)`
  background-color: #7159c1;
`;

export const DatePickerContainer = styled.div`
  top: 42px;
  right: 0;
  min-width: 560px;
  position: absolute;
  background: white;
  border-radius: 8px;
  padding: 8px;
`;
