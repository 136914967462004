import React, { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';

import {
  Container,
  Buttons,
  Button,
  ButtonConfirm,
  DatePickerContainer,
} from './styles.js';

export function DatePicker(props) {
  const [isVisible, setIsVisible] = useState(false);
  const [dateInterval, setDateInterval] = useState({
    from: props.from,
    to: props.to,
  });

  const { from, to } = dateInterval;
  const modifiers = { start: from, end: to };

  function handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, dateInterval);

    setDateInterval(range);
  }

  function handleShowDatePicker() {
    setIsVisible((state) => !state);
  }

  function handleConfirm() {
    props.onChange(dateInterval);
    setIsVisible(false);
  }

  function handleCancel() {
    setDateInterval({
      from: props.from,
      to: props.to,
    });
    setIsVisible(false);
  }

  return (
    <Container>
      <Button type="button" onClick={handleShowDatePicker}>
        {!dateInterval.from && !dateInterval.to && 'Seleccionar fechas'}
        {dateInterval.from && !dateInterval.to && 'Seleccionar fechas'}
        {dateInterval.from &&
          dateInterval.to &&
          `${dateInterval.from.toLocaleDateString()} - ${dateInterval.to.toLocaleDateString()}`}
      </Button>
      {isVisible && (
        <DatePickerContainer>
          <DayPicker
            className="Selectable"
            numberOfMonths={2}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={handleDayClick}
          />

          <Buttons>
            <ButtonConfirm type="button" onClick={handleConfirm}>
              Aplicar
            </ButtonConfirm>
            <Button type="button" onClick={handleCancel}>
              Cancelar
            </Button>
          </Buttons>
        </DatePickerContainer>
      )}
    </Container>
  );
}
