import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { store } from '~/store';
import { signOut } from '~/store/modules/auth/actions';

import AuthLayout from '~/layouts/auth';
import DefaultLayout from '~/layouts/default';

const RouteWrapper = ({
  component: Component,
  isPrivate,
  notFound,
  ...rest
}) => {
  const { signed, exp } = store.getState().auth;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if ((signed && !isPrivate) || notFound) {
    return <Redirect to="/dashboard" />;
  }

  const expiresIn = parseInt(new Date().getTime() / 1000, 10);
  if (exp && expiresIn > exp) {
    store.dispatch(signOut());
    return null;
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  notFound: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  notFound: false,
  component: null,
};

export default RouteWrapper;
