import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import Dashboard from '~/pages/Dashboard';
import Profile from '~/pages/Profile';
import Company from '~/pages/Company';
import CompanyProduct from '~/pages/CompanyProduct';
import Installation from '~/pages/Installation';
import CompanyActivity from '~/pages/CompanyActivity';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/profile" exact component={Profile} isPrivate />
      <Route path="/company/:id" exact component={Company} isPrivate />
      <Route
        path="/company-activity/:id"
        exact
        component={CompanyActivity}
        isPrivate
      />
      <Route
        path="/company/:companyId/product/:id"
        exact
        component={CompanyProduct}
        isPrivate
      />
      <Route
        path="/company/:companyId/product/:companyProductId/installation/:id"
        exact
        component={Installation}
        isPrivate
      />
      <Route isPrivate notFound />
    </Switch>
  );
};

export default Routes;
