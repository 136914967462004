import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const Warpper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  @media (min-width: 620px) {
    padding: 0;
  }
`;

export const Container = styled.div`
  padding: 20px;
  border-radius: 4px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;

  header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;

      h1 {
        color: #fff;
        margin-bottom: 12px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      width: 100%;
      max-width: 600px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
`;

export const EmptyBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  svg {
    margin: 24px auto;
  }
`;

export const AddInstalation = styled(Link)`
  min-width: 110px;
  display: flex;
  align-items: center;
  margin: 5px 0 0;
  padding: 0 12px;
  height: 44px;
  background: #3b9eff;
  color: #fff;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.05, '#3b9eff')};
  }
`;

export const List = styled.section`
  width: 100%;

  .column-1 {
    width: 20%;
  }
  .column-2 {
    width: 40%;
  }
  .column-3 {
    width: 15%;
  }
  .column-4 {
    width: 15%;
  }
  .column-5 {
    width: 10%;
  }
`;

export const ListHeader = styled.div`
  width: 100%;
  display: flex;

  div {
    display: flex;
    align-items: center;
  }

  div,
  button {
    min-height: 40px;
    font-weight: 600;
    color: white;
    font-size: 18px;
    text-align: left;
    padding: 0 16px;
  }
`;

export const HeaderButton = styled.button`
  position: relative;
  background: transparent;
  border: 0;

  ${(props) =>
    props.isOrdered &&
    props.orderDirection === 'desc' &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(5px);
        width: 0;
        height: 0;
        border-top: solid 10px white;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
      }
    `}

  ${(props) =>
    props.isOrdered &&
    props.orderDirection === 'asc' &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(5px);
        width: 0;
        height: 0;
        border-bottom: solid 10px white;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
      }
    `}
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  min-height: 40px;
  margin-top: 4px;
  opacity: ${(props) => (props.isActive ? '1' : '0.6')};

  > a,
  > div,
  > button {
    padding: 0 16px;
    font-weight: 600;
  }

  a {
    color: #3b9eff;
    transition: color 0.2s;
    &:hover {
      color: ${darken(0.05, '#3b9eff')};
    }
  }

  button {
    border: 0;
    background: none;
    color: #f64c75;
    transition: color 0.2s;
    margin-left: 12px;
    &:hover {
      color: ${darken(0.1, '#f64c75')};
    }
  }
`;
