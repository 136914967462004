import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';

import Input from '~/components/Input';

import { signOut } from '~/store/modules/auth/actions';
import { updateProfileRequest } from '~/store/modules/user/actions';

import { Container } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  function handleSubmit(data) {
    dispatch(updateProfileRequest(data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Form initialData={profile} onSubmit={handleSubmit}>
        <Input name="name" placeholder="Nombre completo" />
        <Input
          name="email"
          type="email"
          placeholder="Su dirección de correo electrónico"
        />

        <hr />

        <Input
          name="oldPassword"
          type="password"
          placeholder="Su contraseña actual"
        />
        <Input name="password" type="password" placeholder="Nueva contraseña" />
        <Input
          name="confirmPassword"
          type="password"
          placeholder="Confirmación de contraseña"
        />

        <button type="submit">Actualizar perfil</button>
      </Form>

      <button type="button" onClick={handleSignOut}>
        Salir de la aplicación
      </button>
    </Container>
  );
}
