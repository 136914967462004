import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { FiEdit, FiActivity } from 'react-icons/fi';

import api from '~/services/api';

import { Container, Actions, AddLink, ActionLinks, Company } from './styles';

export default function Dashboard() {
  const [companies, setCompanies] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    async function load() {
      const response = await api.get('companies?page=-1');

      setCompanies(response.data);
    }

    load();
  }, []);

  return (
    <Container>
      <Actions>
        <input
          placeholder="Buscar..."
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <AddLink to="company/-1">
          <MdAdd size={24} color="#fff" /> REGISTRAR
        </AddLink>
      </Actions>
      <ul>
        {companies
          .filter(
            (c) =>
              filter.trim() === '' ||
              c.name
                .toLocaleLowerCase()
                .indexOf(filter.trim().toLocaleLowerCase()) > -1
          )
          .map((company) => (
            <Company key={company.id}>
              <strong>{company.name}</strong>
              <ActionLinks>
                <Link to={`company-activity/${company.id}`} title="Actividad">
                  <FiActivity size={20} />
                </Link>
                <Link to={`company/${company.id}`} title="Modificar">
                  <FiEdit size={20} />
                </Link>
              </ActionLinks>
            </Company>
          ))}
      </ul>
    </Container>
  );
}
