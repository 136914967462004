import React from 'react';
import { Link } from 'react-router-dom';

import { List, Item } from './styles';

export default function ListOfCompanyProducts({ companyId, data, onDelete }) {
  return (
    <List>
      <Item isHeader>
        <strong>Producto</strong>
        <strong>Usuarios</strong>
        <strong>Instalaciones</strong>
        <strong />
      </Item>
      {data.map((item) => (
        <Item key={item.id}>
          <div>{item.product.name}</div>
          <div>{item.concurrent_users}</div>
          <div>{item.allowed_facilities}</div>
          <div>
            <Link
              to={`/company/${companyId}/product/${item.id}`}
              title="Modificar producto"
            >
              Modificar
            </Link>
            <button
              type="button"
              title="Eliminar producto"
              onClick={() => {
                onDelete(item);
              }}
            >
              Eliminar
            </button>
          </div>
        </Item>
      ))}
    </List>
  );
}
